import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline ,ThemeProvider } from '@mui/material';
import theme from './../../theme'

import Layout from '../Layout/Layout';
import HomeDashboard from '../HomeDashboard/HomeDashboard';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<HomeDashboard />} />
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}


export default (App);
