import React from 'react';
import { Box } from '@mui/material';

import Header from '../Header/Header';
import AppContent from '../AppContent/AppContent';
import Footer from '../Footer/Footer';

import BackgroundImage from './background.jpg';

function Layout() {

  return (
    <Box
      sx={{
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '100vw',
        alignItems: 'center',

        backgroundImage: `url(${BackgroundImage})`
      }}
    >
      <Header />
      <AppContent />
      <Footer />
    </Box>
  );
}

export default Layout