import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { QrCodeScanner} from '@mui/icons-material';

import QRCode from '../QRCode/QRCode';

export default function Footer(props) {

  const [openQR, setOpenQR] =  useState(false);

  const handleClickOpen = () => {
    setOpenQR(true);
  };

  return( 
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        color: 'white',
        bgcolor: 'black',
        minHeight: '80px'
      }}>
      <Toolbar
         sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: {
            mobile: 'center',
            tablet: 'space-between',
            laptop: 'space-between',
            desktop: 'space-between'
          },
          alignItems: 'center',
          paddingLeft: '12px',
          paddingRight: '12px',
          minHeight: '56px',
          m:'0px 12px'
        }}
      >
        <Typography
          sx={{
            display:{
              mobile: 'none',
              tablet: 'block',
              laptop: 'block',
              desktop: 'block'
            }
          }}
        >
          #MahsaAmini
        </Typography>
        <IconButton 
          onClick={handleClickOpen}
          color="primary"
          aria-label="open drawer"
          sx={{
            boxSizing: 'border-box',
            textAlign: 'center',
            flex: '0 0 auto',
            borderRadius: '50%',
            overflow: 'visible',
            padding: '12px 0px',
            fontSize: '1.625rem'
          }}
        >
          <QrCodeScanner />
          <Typography
            sx={{
              p: '12px'
            }}
          >
            Be Our Voice!
          </Typography>
        </IconButton>
      </Toolbar>
      <QRCode openQR={openQR} setOpenQR={setOpenQR}/>
    </AppBar>
  )
}