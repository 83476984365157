import React, {useState} from 'react';
import { Paper, Typography, Box, Collapse, Button, CardMedia, Card, CardHeader, IconButton, CardContent, Link } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Twitter } from '@mui/icons-material';

import Mahsa from './mahsa.jpg';
import TieHear from './tie_hair_greyscale.png';
import Leader from './leader_greyscale.png';
import Support from './support_greyscale.png';

function HomeDashboard() {
  const [expandedAnswer, setExpandedAnswer] = useState(null);

  const handleClick = (value) => {
    const v = expandedAnswer === value ? 'null' : value;
    setExpandedAnswer(v);
  }

  return(
      <Paper
        color='transparent'
        elevation={0}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems:'center',
          padding: '0px 24px',
          maxWidth:{
              mobile: '400px',
              tablet: '500px',
              laptop: '700px',
              desktop: '960px',
            },
          m: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            width: '100%',
            height: `calc(100vh - 148px)`,
            alignItems:'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              width: {
                mobile: '40%',
                tablet: '40%',
                laptop: '40%',
                desktop: '40%',
              },
              mt: {
                  mobile: '36px',
                  tablet: '36px',
                  laptop: '48px',
                  desktop: '84px'
                },
            }}
          >
            <Typography     
              variant="h5" 
              sx={{
                fontFamily: 'PoppinsExtraBold',
                lineHeight: '1',
                color:'rgb(35,159,63)'
              }}
            >
              WOMAN
            </Typography>
            <Typography     
              variant="h5" 
              sx={{
                fontFamily: 'PoppinsExtraBold',
                lineHeight: '1',
                color:'white'
              }}
            >
              LIFE
            </Typography>
            <Typography     
              variant="h5" 
              sx={{
                fontFamily: 'PoppinsExtraBold',
                lineHeight: '1',
                color:'rgb(219,1,0)'
              }}
            >
              FREEDOM
            </Typography>
            <Typography
              variant="h7" 
              sx={{
                padding: {
                  mobile: '6px 0px',
                  tablet: '6px 0px',
                  laptop: '12px 0px',
                  desktop: '12px 0px'
                },
                lineHeight: '1',
                fontWeight:'bold',
                color:'rgb(66,66,66)'
              }}
            >
              Stand in solidarity with Iranian women. 
              <br/><br/>
              <i>This is a REVOLUTION.</i>
            </Typography>
            <Typography
              variant="h7" 
              sx={{
                padding: {
                  mobile: '6px 0px',
                  tablet: '6px 0px',
                  laptop: '12px 0px',
                  desktop: '12px 0px'
                },
                lineHeight: '1',
                fontWeight:'bold',
                color:'rgb(66,66,66)',
              }}
            >
              #MahsaAmini
            </Typography>
          </Box>



          <Box
           sx={{
            display: 'flex',
            flexFlow: 'column',
           }}
          >
             <CardMedia
              component="img"
              image={TieHear}
              alt="Women"
              sx={{
                mb: {
                  mobile: '140px',
                  tablet: '120px',
                  laptop: '180px',
                  desktop: '200px'
                },
              }}
              />
          </Box>
        </Box>



        <Box
          sx={{
            display: 'flex',
            flexFlow: {
              mobile: 'column-reverse',
              tablet: 'column-reverse',
              laptop: 'row',
              desktop: 'row',
            },
            width: '100%',
            alignItems:'center',
            justifyContent: 'center',
          }}
        >
        <Box
          sx={{
          width: {
            mobile: '100%',
            tablet: '100%',
            laptop: '50%',
            desktop: '50%',
          },
          height: '100%',
          }}
        >
          <CardMedia
            component="img"
            image={Leader}
            alt="Women"
            sx={{
              width: '80%',
              m: 'auto',
            }}
          />
        </Box>
        <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              width: {
                mobile: '100%',
                tablet: '100%',
                laptop: '50%',
                desktop: '50%',
              },
              m: 'auto',
            }}
          >
        <Typography
          variant='body1' 
          sx={{
            padding: '48px 0px',
            m: 'auto',
          }}
        >
          Mass protests and social unrest ignited following the death of #MahsaAmini, a 22-year-old Iranian girl, who died while being in police custody. 
          She was severely beaten by morality police for not wearing her hijab in a manner mandated by Islamic Republic. #MahsaAmini
        </Typography>
        </Box>
        </Box>


        <Box
          sx={{
            display: 'flex',
            flexFlow: {
              mobile: 'column',
              tablet: 'column',
              laptop: 'row',
              desktop: 'row',
            },
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              width: {
                mobile: '100%',
                tablet: '100%',
                laptop: '50%',
                desktop: '50%',
              },
              m: 'auto',
            }}
          >
        <Typography
          variant='body1' 
          sx={{
            padding: '48px 0px',
            m: 'auto',
          }}
        >
          #MahsaAmini's death has united Iranians across the globe to demand justice for her, 
          and fighting for women's basic human rights and freedom.
          The Islamic Regime is suppressing the protests in Iran by targeting protesters using live ammunition, tear gas and water cannons. 
          Meanwhile, all access to the Internet is severely limited, effectively cutting the protesters off from the rest of the World.  #WomanLifeFreedom
        </Typography>
        </Box>
        <Box
           sx={{
            width: {
              mobile: '100%',
              tablet: '100%',
              laptop: '50%',
              desktop: '50%',
            },
            height: '100%',
            m: 'auto',
           }}
          >
             <CardMedia
              component="img"
              height="80%"
              image={Support}
              alt="Women"
              sx={{
                borderRadius: '10px',
                m: 'auto',
              }}
              />
        </Box>
        </Box>




         <Button
          variant='outlined'
          color='secondary'
          sx={{
            width: '300px',
            borderRadius: '20px',
            m: '24px 48px'
          }}
        >
          Want to know more?
        </Button>



        <Card 
          sx={{ 
            width: '100%', 
            border: "1px solid rgba(211,211,211,0.6)", 
            bgcolor: 'rgba(0,0,0,0.1)' 
          }}
        >
        <CardHeader
          disableTypography
          title='How can I stand with Iranian women?'
          action={
            <IconButton
              onClick={(e) => handleClick('4')}
              aria-label="expand"
              size="small"
            >
              {expandedAnswer === '4' ? <KeyboardArrowUp sx={{color:'black'}} /> : <KeyboardArrowDown sx={{color:'black'}}/>}
            </IconButton>
          }
        >
        </CardHeader>
        <Box style={{ backgroundColor: 'rgba(211,211,211,0.1)'}}>
          <Collapse in={expandedAnswer === '4'} timeout="auto" unmountOnExit>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: {
                    mobile: 'column',
                    tablet: 'row',
                    laptop: 'row',
                    desktop: 'row',
                  },
                }}
              >
                <Typography
                  variant='h8' 
                  align= 'justify'
                  sx={{
                    fontWeight: 'bold',
                    padding: '24px'
                  }}
                >
                  By amplifying their voice!
                  <br/><br/>
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignContent: 'center'
                    }}
                  >
                    <Button
                      variant='outlined'
                      color='secondary'
                      href="https://twitter.com/intent/tweet?text=I%20stand%20with%20the%20Iranian%20people%20and%20%23MahsaAmini%20movement.%20%23%D9%85%D9%87%D8%B3%D8%A7_%D8%A7%D9%85%DB%8C%D9%86%DB%8C%20%23OpIran%20%20&hashtags=MahsaAmini"
                      aria-label="expand"
                      size="small"
                      sx={{
                        borderRadius: '20px'
                      }}
                    >
                    <Twitter sx={{color:'rgb(29,161,242)'}} /> 
                      Tweet
                    </Button>
                  </Box>
                  <br/><br/>
                  By signing the following petitions:
                  <br/>
                    Global:
                  <br/>
                  <Link href="https://action.amnesty.org.au/act-now/iran-end-the-protest-bloodshed">
                    Amnesty
                  </Link>
                  <br/>
                    Australia:
                  <br/>
                  <Link href="https://www.aph.gov.au/e-petitions/petition/EN4559">
                    Expel the Islamic Republic of Iran ambassador, sanction IRGC
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Collapse>
        </Box>
      </Card>



        <Card 
          sx={{ 
            width: '100%', 
            border: "1px solid rgba(211,211,211,0.6)", 
            bgcolor: 'rgba(0,0,0,0.1)' 
          }}
        >
        <CardHeader
          disableTypography
          title='Who is #MahsaAmini (مهساـامینی#) ?'
          action={
            <IconButton
              onClick={(e) => handleClick('1')}
              aria-label="expand"
              size="small"
            >
              {expandedAnswer === '1' ? <KeyboardArrowUp sx={{color:'black'}} /> : <KeyboardArrowDown sx={{color:'black'}}/>}
            </IconButton>
          }
        >
        </CardHeader>
        <Box style={{ backgroundColor: 'rgba(211,211,211,0.1)'}}>
        <Collapse in={expandedAnswer === '1'} timeout="auto" unmountOnExit>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexFlow: {
                  mobile: 'column',
                  tablet: 'column',
                  laptop: 'row',
                  desktop: 'row',
                },
                alignItems:'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='body1' 
                sx={{
                  padding: '24px'
                }}
              >
                #MahsaAmini was a young girl from Saqqez, Iran, who wanted to spend a few days in Tehran with her Family.
                While she was in Tehran, she was arrested by the Morality Police, and after within a few hours, 
                she was transferred to the hospital due to severe traumatic brain injury. After two days in a coma, the 22-year-old passed away.
              </Typography>
              <CardMedia
                component="img"
                height="300"
                image={Mahsa}
                alt="Mahsa Amini"
                sx={{
                  borderRadius: '10px',
                  width: '300px',
                  padding: '24px'
                }}
              />
            </Box>
          </CardContent>
        </Collapse>
        </Box>
      </Card>


      <Card 
          sx={{ 
            width: '100%', 
            border: "1px solid rgba(211,211,211,0.6)", 
            bgcolor: 'rgba(0,0,0,0.1)'  
          }}
        >
        <CardHeader
          disableTypography
          title='What do we want?'
          action={
            <IconButton
              onClick={(e) => handleClick('2')}
              aria-label="expand"
              size="small"
            >
              {expandedAnswer === '2' ? <KeyboardArrowUp sx={{color:'black'}} /> : <KeyboardArrowDown sx={{color:'black'}}/>}
            </IconButton>
          }
        >
        </CardHeader>
        <Box style={{ backgroundColor: 'rgba(211,211,211,0.1)'}}>
          <Collapse in={expandedAnswer === '2'} timeout="auto" unmountOnExit>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: {
                    mobile: 'column',
                    tablet: 'row',
                    laptop: 'row',
                    desktop: 'row',
                  },
                }}
              >
                <Typography
                  variant='body1' 
                  sx={{
                    padding: '24px'
                  }}
                >
                  It is simple, Islamic Regime must go! 
                  <br/><br/>
                  Iranians do not want to be ruled by a religious theocracy that treats all women as second-class citizens, 
                  subjugating the entire country to medieval religious laws and denying people their Human Rights.  
                  Iranians want to be free and prosper like any other people starting with the liberation of Iranian Women from institutional oppression, 
                  fear, and carnage. 
                  <br/><br/>
                  We are calling for <i>WOMAN, LIFE, FREEDOM</i>, encompassing our desire to be free, liberated from religious theocracy, 
                  and pursue happiness and close relationship with other countries in the Middle East and the World. Despite the Regime's insistence, 
                  the Iranian People do not want to have a conflict or war with anyone.
                  <br/><br/>
                  We do not believe in calling for the destruction of any country, people, or nation. 
                  We believe in peaceful coexistence and respect for Human Rights and Human Dignity regardless of gender, colour, race and religion. 
                  We want the World to know this Islamic Regime is not representing the Iranian People.
                </Typography>
              </Box>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card 
          sx={{ 
            width: '100%', 
            border: "1px solid rgba(211,211,211,0.6)", 
            bgcolor: 'rgba(0,0,0,0.1)' 
          }}
        >
        <CardHeader
          disableTypography
          title='What do we want you to know?'
          action={
            <IconButton
              onClick={(e) => handleClick('3')}
              aria-label="expand"
              size="small"
            >
              {expandedAnswer === '3' ? <KeyboardArrowUp sx={{color:'black'}} /> : <KeyboardArrowDown sx={{color:'black'}}/>}
            </IconButton>
          }
        >
        </CardHeader>
        <Box style={{ backgroundColor: 'rgba(211,211,211,0.1)'}}>
          <Collapse in={expandedAnswer === '3'} timeout="auto" unmountOnExit>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: {
                    mobile: 'column',
                    tablet: 'row',
                    laptop: 'row',
                    desktop: 'row',
                  },
                }}
              >
                <Typography
                  variant='body1' 
                  sx={{
                    padding: '24px'
                  }}
                >
                  What you see and hear about Iran is no longer a mere protest.
                  <i> A REVOLUTION HAS STARTED</i>. 
                  <br/><br/>
                  We have changed many things, broken many of the Regime's taboos, and demanded our rights and freedom. 
                  <br/><br/>
                  We will continue until Iranian women break the chains of a religious, medieval misogynist theocracy and have the life and liberty they deserve. 
                  This is a Revolution against gender apartheid!    
                  <br/><br/>
                  <i>WE WILL CONTINUE UNTIL OUR REVOLUTION SUCCEEDS AND IRAN IS LIBERATED</i>.
                </Typography>
              </Box>
            </CardContent>
          </Collapse>
        </Box>
      </Card>



      </Paper>
  )
};

    
export default HomeDashboard;