import { createTheme } from '@mui/material/styles';
import PoppinsTtf from './fonts/Poppins-Regular.ttf';
import PoppinsExtraBoldTtf from './fonts/Poppins-ExtraBold.ttf';
import BebasNeueTtf from './fonts/BebasNeue-Regular.ttf';


let theme = createTheme({
  palette: {
    text:{
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#fff',
    },
    primary: {
      main: '#fff',
      dark: '#1A2634',
      contrastText: '#fff',
    },
    secondary: {
      // main: '#4c97c9',
      main: '#000000',
      dark: '#195176',
      contrastText: '#fff',
    },
  },
  breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1280,
      },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'BebasNeue',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme.typography.h5 = {
  fontSize: '3.2rem',
  [theme.breakpoints.between('tablet','laptop')]: {
    fontSize: '4.2rem',
  },
  [theme.breakpoints.between('laptop','desktop')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('desktop')]: {
    fontSize: '9rem',
  },
};

theme.typography.h6 = {
  fontSize: '1.4rem',
  [theme.breakpoints.between('tablet','laptop')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.between('laptop','desktop')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('desktop')]: {
    fontSize: '2.0rem',
  }
};

theme.typography.h7 = {
  fontSize: '1.0rem',
  [theme.breakpoints.between('tablet','laptop')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.between('laptop','desktop')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.8rem',
  }
};

theme.components = {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsTtf}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }


        @font-face {
          font-family: 'PoppinsExtraBold';
          font-style: bold;
          font-display: swap;
          font-weight: 900;
          src: local('Poppins'), local('Poppins-ExtraBold'), url(${PoppinsExtraBoldTtf}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'BebasNeue';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BebasNeue'), local('BebasNeue-Regular'), url(${BebasNeueTtf}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        cell: {
          '&:focus': { outline: 'none' },
          '&:focus-within': { outline: 'none' },
        },
        columnSeparator: {
          display: 'none'
        },
        columnHeaders: {
          backgroundColor: 'rgba(0, 64, 102, 0.08)',
          borderBottom: 'none',
          borderRadius: '5px',
        },
        columnHeader: {
          '&:focus': { outline: 'none' },
          '&:focus-within': { outline: 'none' },
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundColor: 'transparent',
          
        },
      }
    }
};

export default theme;