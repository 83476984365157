import React from 'react';
import { AppBar, Toolbar } from '@mui/material';

function Header() {

  return(
      <AppBar
        position= 'static'
        color="transparent"
        sx={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 'max(68px, 68px + env(safe-area-inset-top))',
          boxSizing: 'border-box',
          flexShrink: '0',
          zIndex: '1100',
          top: '0px',
          left: 'auto',
          right: '0px',
        }}
      >
        <Toolbar
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
            minHeight:  '56px',
            boxSizing: 'border-box',
            mt: 'auto'
          }}
        >
        </Toolbar>
      </AppBar>
  )
}
    
export default Header;
