import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

function AppContent(props) {
  return(
    <Box
      color="transparent"
      sx={{
        flex: '1 1 0%',
        width:'100%',
        height: '100%',
        // ml: 'auto',
      }}
    >
      <Outlet />
      <Box sx={{ height: '80px' }} />
    </Box>
  )
}
    
export default AppContent;