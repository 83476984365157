import React from 'react';
import { Box, Dialog, DialogContent,  DialogActions, Button, CardMedia } from '@mui/material';

import UniteforiranQrcode from './uniteforiran_qrcode.png';

function QRCode(props) {

  const handleClose = () => {
    props.setOpenQR(false);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `uniteforiran_qrcode.png`;
    link.href = UniteforiranQrcode;
    link.click();
  };

  return(
    <Box>
      <Dialog
        // fullScreen={fullScreen}
        open={props.openQR}
        onClose={handleClose}
        aria-labelledby="qrcode-dialog"
      >
        {/* <DialogTitle id="qrcode-dialog">
          Share the word!
        </DialogTitle> */}
        <DialogContent
          sx={{
            background:'rgba(0,0,0,0.7)'
          }}
        >
        <CardMedia
            component="img"
            maxHeight="400px"
            image={UniteforiranQrcode}
            alt="Women"
            sx={{
              borderRadius: '10px',
              maxWidth: '400px',
              m: 'auto',
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            background:'rgba(0,0,0,0.7)'
          }}
        >
          <Button onClick={onDownload} variant="outlined" color="primary">
            Download
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
    
export default QRCode;
